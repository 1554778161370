.reset-password-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reset-password-header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3em;
    h2{
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 70px;
        color: #2B364E;
    }
    span{
        font-weight: 400;
        font-size: 1rem;
        line-height: 20px;
        color: #0FABE0;
    }
}

.reset-password-input{
    min-width: 80%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    height:45px;
    input{
        width: 100%;
        height: 100%;
        font-family: inherit;
        padding: 1em;
        background: #F9F9F9;
        letter-spacing: 2px;
        box-shadow: 6px 6px 13px rgba(159, 153, 153, 0.25);
        border-radius: 9px;
        border:none;
        outline: none;
        font-size:1.5rem;
        line-height: 50px;
        color:#807C7C;
        &:focus{
            outline: 2px solid #0FABE0;
        }
    }
    img{
        width:25px;
        height: 25px;
        position: absolute;
        right:2%;
        cursor: pointer;
    }
}


.reset-password-requirements{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}

.reseted-password-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em 0;
    p{
        line-height: 30px;
        color: #2b364e; 
        width:400px;
        text-align: center;
    }
}


.validated-container{
    position: relative;
}

.validated-check-small{
    position: absolute;
    top:37.5%;
    left:37.5%;
}
.validated-check-large{
    position: absolute;
    top:23%;
    left:23%;
}


.reset-button{
    font-family: 'Poppins';
    width:260px;
    height:43px;
    border: none;
    color: white;
	background: #0fabe0;;
    border-radius: 6px;
    line-height: 118%;
	font-size: 15px;
	font-weight: 600;
	cursor: pointer;
}
.reset-button-disabled{
    font-family: 'Poppins';
    width:260px;
    height:43px;
    border: none;
    color: white;
	background: #807C7C;
    border-radius: 6px;
    line-height: 118%;
	font-size: 15px;
	font-weight: 600;
}

@primary-color: #5B71F3;