.selected__report {
	background-color: #f0f7ff;
	height: 46px;
	color: #0998c9;
	font-size: 21;
	font-weight: 500;
	padding-left: 20px;
	padding-top: 5px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-top-width: 2px;
	border-left-width: 2px;
	border-right-width: 2px;
}
.selected__report__img {
	border-bottom-width: 2px;
	border-left-width: 2px;
	border-right-width: 2px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
.scroll__view {
	width: 181px;
	height: 230px;
	margin-right: 60px;
	margin-left: 30px;
	margin-top: 10px;
}
.scroll__view__hovered {
	width: 181px;
	height: 40px;
	margin-right: 60px;
	margin-left: 30px;
	margin-top: 10px;
	text-align: center;
}
.card {
	height: 146px;
	border-width: 2px;
	max-height: 200px;
	min-height: 146px;
	border-radius: 10px;
	background-color: #fff;
}
.card__hovered {
	height: 80px;
	border-width: 2px;
	max-height: 100px;
	min-height: 80px;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0.4rem;
}
.card__img {
	opacity: 1;
	height: 136px;
	width: 400px;
	border-radius: 8px;
}
.card__img:hover {
	opacity: 0.5;
	height: 136px;
	width: 400px;
	border-radius: 8px;
}
.card__img_non_click {
	opacity: 1;
	height: 136px;
	width: 400px;
	border-radius: 8px;
}
