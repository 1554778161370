@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}
.parent {
	display: flex;
}
.logo__section {
	background-color: #2b364e;
	height: 100vh;
	width: 50vw;
}
.circles {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	width: 100%;
	background-image: url("../../../assets//svg/auth-circles.svg");
	background-size: 100% 100%;
	-o-background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	background-size: cover;
}
.logo {
	width: 100px;
	height: 120px;
}
.brand {
	color: white;
	font-family: "Inter", sans-serif;
	font-size: 5rem;
	font-weight: 300;
}
.auth__section {
	width: 50vw;
	height: 100vh;
	padding: 0 8rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 3rem;
}
.auth__section__otp {
	width: 50vw;
	height: 100vh;
	padding: 4rem 8rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 3rem;
}
.auth__section__signup {
	width: 90%;
	height: 100vh;
	padding: 2rem 8rem 0 8rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.welcome__section {
	display: flex;
	flex-direction: column;
}
.title__regular {
	font-size: 40px;
	font-weight: 400;
	color: #0f80b0;
}
.title__bold {
	color: #2b364e;
}
.subtitle {
	color: #727272;
	font-size: 27px;
}
.credentials__section {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-height: 60vh;
	gap: 1.5rem;
}
.otp__section {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
}
.auth__form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}
.auth__form__signup {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
}
.auth__label {
	width: 100%;
	font-size: 16px;
	font-weight: 500;
}
.auth__input {
	width: 100%;
	padding: 8px 10px;
	background: #f9f9f9;
	border: 0.5px solid #acaaaa;
	box-shadow: 2px 2px 6px rgba(159, 153, 153, 0.25);
	border-radius: 9px;
}
.phone__section {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 4fr;
	gap: 1rem;
}
.group__box {
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
}
.terms {
	font-size: 12px;
	font-weight: 400;
	color: #6d6b6b;
}
.auth__btn {
	padding: 0.4rem 2rem;
	border: none;
	color: white;
	background: #0fabe0;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	font-size: 1.3rem;
	font-weight: 600;
	cursor: pointer;
}
.redirection__btn {
	font-size: 14px;
	color: #888686;
	border: none;
	background: none;
}
.redirection__btn:hover {
	text-decoration: underline;
	cursor: pointer;
}
.redirection__bold {
	color: #7bd3ef;
}
.other__section {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.line__breaker {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}
.hr {
	display: block;
	width: 33%;
	height: 1.3px;
	background: rgba(131, 126, 126, 0.3);
}
.line__text {
	font-size: 14px;
	color: #888686;
	font-weight: 400;
}
.auth__other {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}
.rect__box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.4rem;
	border: 0.1px solid #d6d3d3;
	border-radius: 6px;
	cursor: pointer;
}

.radio__box {
	width: 100%;
	display: flex;
	gap: 1rem;
	align-items: center;
}

.radio__box > label {
	font-size: 14px;
}

input[type="time"]:before {
	content: "10:00 AM";
	color: #9d9d9d;
	position: absolute;
	background: #fff;
	width: 70px;
}

input[type="time"]:focus:before {
	width: 0;
	content: "";
}
.group {
	width: 100%;
	display: flex;
	gap: 2rem;
	align-items: center;
	font-size: 20px;
}
