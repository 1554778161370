.strength-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.strength-header{
    margin-bottom: 1em;
    color: #807C7C;
}


.parameter-col{
    display: flex;
    flex-direction: column;
    gap: 0.6em 0;
}

.parameters{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0 2em;
}

/* .parameter{
    display: flex;
    align-items: center;
    gap:0 1em;
}

.check{
    width:12px;
    height: 12px;
    background: transparent;
    border: 2px solid #0FABE0;
    border-radius: 50%;
}

.param{
    color: #807C7C;
} */