.parameter{
    display: flex;
    align-items: center;
    gap:0 1em;
}

.check{
    width:12px;
    height: 12px;
    background: transparent;
    border: 2px solid #0FABE0;
    border-radius: 50%;
}

.fill{
    width:12px;
    height: 12px;
    border: 2px solid #0FABE0;
    border-radius: 50%;
    background-color: #0FABE0;
}

.param{
    color: #807C7C;
}