  .domain.x-axis {
    display: none;
  }
  .axis path,
  .axis line {
    fill: none;
    stroke: #ccc; /* color of the ticks and lines */
    shape-rendering: crispEdges;
  }
  .axis path {
    display: none;
  }