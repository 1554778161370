.green__button{
    background-Color:#35B920;
    border-Radius:6px;
    padding:0.4rem 0.6rem;
    color:white;
    font-Weight:600;
}

.download__button{
    display: flex;
    align-Items: center;
    border-Radius:15px;
    font-Weight: 600;
    padding: 0.45rem 1rem;
    background-Color: #F0F7FF;
    border: 0.4px solid #0FABE0;
    color: #0FABE0;
    margin-right: 1rem;
}

.auth__button {
	padding: 0.4rem 2rem;
	border: none;
	color: white;
	background: #0fabe0;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	font-size: 1.3rem;
	font-weight: 600;
	cursor: pointer;
}



.filters__button{
    background-color: #edf2f7;
    color: black;
}

.selected{
    background-color: #0FABE0;
    color:white;
}