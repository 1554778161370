html {
	overflow: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 0;
	/* Remove scrollbar space */
	background: transparent;
	/* Optional: just make scrollbar invisible */
}

::-webkit-calendar-picker-indicator {
	filter: invert(57%) sepia(79%) saturate(2291%) hue-rotate(157deg) brightness(94%) contrast(89%);
}

/* This prevents search box to come over the universal loader */
.css-v9b9hc,
.css-6plmwk,
.css-hdd9l7 {
	z-index: 99999 !important;
}

/* To make rounded corner in trade box inside Summury Screen */
.css-mwvq0s {
	border-top-left-radius: 15PX;
	border-top-right-radius: 15px;
}

/* 
	This makes label color to white in Pie chart, while hovering legend. 
	(If not set to 1, it will be partially visible) 
*/
.apexcharts-datalabels {
	opacity: 1 !important;
}

.sortIconSummuryScreen {
	display: inline-block;
}

.h-40 {
	height: 10rem;
}

.min-h-144 {
	min-height: 36rem;
}