.positionTableRow {
    cursor: pointer;
}

.disabledInput {
    color: rgba(0, 0, 0, 0.435);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    border-radius: 2px;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    padding: 5px 0px 5px 12px;
}